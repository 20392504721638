.block-broodtekst {
  &.bg-dark-transparent {
    background-color: $darkTransparent;
  }
  &.bg-light-transparent {
    background-color: $lightTransparent;
  }
}

.container.broodtekst {
  .row {
    .broodtekst__ {
      &heading,
      &item {
        *:last-child {
          margin-bottom: 0;
        }

        ul, ol {
          padding-left: 1rem;
          li {
            position: relative;
            font-family: $apercu-regular-pro;
            font-size: 1.125rem;
            line-height: 1.625rem;
            padding-left: 2rem; 

            &::before {
              content: "\2022"; 
              position: absolute;
              left: 0;
              top: 0.3rem; 
              font-size: 20px;
              line-height: 20px;
              color: inherit;
            }

            & strong,
            i,
            em {
              display: contents;
            }
          }
        }

        p {
          font-size: 1.125rem;
          a {
            color: $sdb-orange-hieroo-dark;
            text-decoration: underline;
            text-decoration-color: $orange;
          }
        }
      }
      &item {
        h3:not(:first-of-type) {
          margin-top: 3.75rem;
        }
      }
    }
  }
}
