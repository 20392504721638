/* Breadcrumb & Label icons */
i {
	display: inline-block;
	height: 11px;
	margin: 1px 6px 2px 0; // height 13px to align with tags
    vertical-align: bottom;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
	&.pencil {
		background-image: url('/assets/img/icons/pencil.svg');
		width: 16px;
	}
	&.book {
		background-image: url('/assets/img/icons/book.svg');
		width: 9px;
	}
	&.books {
		background-image: url('/assets/img/icons/books.svg');
		width: 11px;
	}
	&.clock {
		background-image: url("/assets/img/icons/clock.svg");
		width: 11px;
	}
}
.breadcrumbs {
	a {
		 color: $dark;
		 font-family: $apercu-mono-medium-pro;
	}
}