.container-hero {
	padding-top: 80px;
	padding-bottom: 70px;
}

.hero {
    &__picture {
        overflow: hidden;
        position: absolute;
        width: 100%;
        height: 100vh;
        right:0;
        left:0;
    }

    &__img {
        object-fit: cover;
    }

    &__content {
        display: flex;
        flex-direction: column;
        height: 100vh;
    }

    &__scroll {
        z-index: 2;
        justify-content:center;
    
        @media screen and (max-width: 375px) {
            display:none;
        }
    }
    &__video {
        object-fit: cover;
        overflow: hidden;
        width: 100%;
        height: 100vh;
        position: absolute;
        left: 0;
        right: 0;
    }
}