.block-form { 
    background-color: $light;
    &.half {
        @include media-breakpoint-up(md) {
            background-color: transparent;
        }
        form {
            padding-bottom: 40px;
        }
    }
    
    form {
        background-color: $light;
        font-family: $apercu-regular-pro;

        input[type=text],
        input[type=email],
        input[type=checkbox],
        input[type=radio]{
            font-size: 1.125rem;
            line-height: 1.375rem;
            &:focus {
                outline:0px !important;
                -webkit-appearance:none;
                box-shadow: none !important;
            }
            &:active {
                border-color: $dark;
            }
        }

        /* Input */
        .form-label { width: 100%; }
        .form-check-label { cursor: pointer;}
        .form-check-input { margin-top: 0;}
        .input-group {
            position: relative;

            input[type=text],
            input[type=email]{
                
                height: $input-height;
                color: $input-plaintext-color;
                border-top-left-radius: $border-radius;
                border-bottom-left-radius: $border-radius;

                @include media-breakpoint-up(md) {
                    height: $input-height-lg;
                }
    
                &:hover { border: 2px solid $dark; }
                &:invalid { border: 2px solid $orange; 
                    &::placeholder { font-style: normal; color: $input-plaintext-color; }
                }
                /* Placeholder */
                &::placeholder { font-style: italic; font-family: $Garamond-BkNarrowIta; }
                &:invalid,
                &.is-invalid { 
                    background-color: $orange-light; color: $dark; font-style: normal; border: 2px solid $orange}
                    &.has-error::placeholder{
                        color: $dark;
                    }
            }
                
            .invalid-feedback {
                color: $sdb-orange-hieroo-dark;
                position: absolute;
                right: 0;
                bottom: -30px;
                width: auto;
            }
            & > .submit.text {
                &-spread {
                    display: flex;
                    justify-content: space-between;
                }
            }
        }
        
        input:-webkit-autofill,
        textarea:-webkit-autofill,
        select:-webkit-autofill{
            -webkit-text-fill-color: $dark;                                
            -webkit-box-shadow: 0 0 0 1000px $white inset !important;
            background-color: rgba(255, 255, 255, 1) !important;
            box-shadow: none;

            &:focus,
            &:active {
                background-color: rgba(255, 255, 255, 1);
                color: $dark;
            }
        }
        
        /* Checkbox */
        input[type=checkbox] {
            border: $form-check-input-border;
            border-radius: $form-check-input-border-radius; 
            background-size: 14px;
            &:invalid,
            &.is-invalid { background-color: $orange-light; border: 2px solid $orange; }
        }

        /* Radio */
        input[type=radio] {
            width: 24px; height: 24px;
            &:checked { 
                background-color: $orange; 
                border: 3px solid $white; 
            }
            
            @include media-breakpoint-up(md) {
                width: 20px; height: 20px;
            }
        }
        button { height:40px; }
        .alert {
            text-align: center;
            &.errors {
                p {
                    color: $sdb-orange-hieroo-dark;
                }
            }
        }
    }
}