.constrain {
  max-width: 1115px;
}

ul,
ol {
  margin: 0;
  padding: 0;
  li {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
}

.lead {
  font-size: $lead-size;
  line-height: $lead-line-height;
  letter-spacing: $lead-letter-spacing;
}

.tag {
  font-size: $tag-size;
  line-height: $tag-line-height;
  font-family: $apercu-mono-medium-pro;
  &.fw-500 {
    font-weight: 500;
  }
}

.caption {
  font-size: $caption-size;
  line-height: $caption-line-height;
  font-family: $apercu-medium-pro;
}

/* Non-standard spacing between components */
.header- {
  &pagina {
    + .block- {
      &anchor-nav {
        @include media-breakpoint-up(md) {
          margin-top: 100px;
        }
      }
    }
  }
  &pagina,
  &persoon {
    & h1 {
      @include media-breakpoint-down(md) {
        font-size: 3.25rem;
        line-height: 2.625rem;
        letter-spacing: -1.49px;
      }
    }
  }
}

section {
  /* Scroll to */
  scroll-margin-top: 7.5rem !important;
  @include media-breakpoint-down(md) {
    scroll-margin-top: 4.5rem !important;
  }

  /* Spacing */
  padding-bottom: 80px;
  @include media-breakpoint-up(md) {
    padding-bottom: 120px;
  }
  &.bg-light,
  &.bg-dark,
  &.bg-light-transparent,
  &.bg-dark-transparent,
  &.block-form:not(.half) {
    padding-top: 80px;
    margin-bottom: 80px;
    @include media-breakpoint-up(md) {
      padding-top: 120px;
      margin-bottom: 120px;
    }
    /* No margin needed if we have back-to-back bg-colors */
    &:has(+ section.bg-light),
    &:has(+ section.bg-dark),
    &:has(+ section.bg-light-transparent),
    &:has(+ section.bg-dark-transparent) {
      margin-bottom: 0;
    }
  }
  /* Exceptions */
  &.sticky__wrapper {
    padding: 0 !important;
  }
  &.bg-light + section.bg-light,
  &.bg-dark + section.bg-dark,
  &.bg-light-transparent + section.bg-light-transparent,
  &.bg-dark-transparent + section.bg-dark-transparent,
  &.block-form:not(.half) + section.block-form:not(.half) {
    padding-top: 0;
  }
}
