.momdal-backdrop {
    background-color: $dark;
    opacity: 0.8;
}
.modal-open {
    main {
        -webkit-filter: blur(10px);
        -moz-filter: blur(10px);
        -o-filter: blur(10px);
        -ms-filter: blur(10px);
        filter: blur(10px);
    }
}