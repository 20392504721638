.static {
    &__container {
        position: relative;
        overflow: hidden;
    }
    &__img {
        object-fit: cover;
        min-height: 520px;
        padding-bottom: 2rem;
    }
    &__content {
        position: absolute; 
        width: 100%;
        height: auto;
        left: 0;
        right: 0;
        bottom: 120px;
        @include media-breakpoint-down(sm) {
            bottom: 100px;
        }
    }
}
