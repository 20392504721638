.intro-text__container {
  a {
    text-decoration: underline;
  }

  .row {
    .col-sm-8 {
      *:last-child {
        margin-bottom: 0;
      }
    }
  }

  p {
    font-size: 1.5rem;
    line-height: 2rem;
    letter-spacing: -0.2px;

    @include media-breakpoint-up(md) {
      font-size: 1.75rem;
      line-height: 2.375rem;
      letter-spacing: -0.2px;
    }
  }
}
