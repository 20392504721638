// File: main.scss
$project-name: "SDB Website - Critical";
$version: "0.0.1";

/*! #{$project-name} v#{$version} */

@import "abstracts/mixins";

// bootstrap
@import "vendors/bootstrap";

// non-bootstrap variables
@import "abstracts/variables";

// base
@import "base/base";
@import "base/typography";
@import "base/buttons";
@import "base/form";
@import "base/modals";

// layout
@import "layout/nav";
@import "layout/structure";

// components / modules
@import "components/breadcrumbs";
@import "components/cookie-modal";
@import "components/cookies";
@import "components/hero";
@import "components/image";
@import "components/broodtekst";
@import "components/intro-text";
@import "components/static-header";

// pages
