#cookieModal,
#cookieModalSettings {
    .modal-content {
        background: $light;
        border-radius: 0;

        padding: 40px 20px;

        @include media-breakpoint-up(md) {
            padding: 40px;
        }

        .cookie-body {

            p {
                font-size: 0.875rem;
                line-height: 1.25rem;
                a {
                    &.fw-normal {
                        font-family: $apercu-regular-pro;
                      }
                }
            }

            .btn {
                margin-top: 0;
                margin-bottom: 0;

            }
            .info {
                height: 20px;
                width: 20px;
                background-image: url('/assets/img/icons/info-dot-passive.svg');
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                vertical-align: middle;
                cursor:pointer;
                &:hover {
                    background-image: url('/assets/img/icons/info-dot-active.svg');
                }
            }
        }
    }

}

.tooltip .tooltip-inner {
    text-align: left;
}