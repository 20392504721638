#cookie-banner {
    position: fixed;
    bottom: 0;
    background: $light;
    z-index: 90;
    display: none;
    p {
        font-size: 0.75rem;
        line-height: 1.25rem;
        a {
            &.fw-normal {
                font-family: $apercu-regular-pro;
              }
        }
        strong {
            @include media-breakpoint-down(md) {
                display: block;
                font-size: 1rem;
                line-height: 1.375rem;
                margin-bottom: 10px;
            }
        }
    }
}