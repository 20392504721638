.nav {
  ul,
  ol {
    margin: 0;
    padding: 0;
    li {
      margin: 0;
      padding: 0;
      list-style-type: none;
    }
  }
  z-index: 80;
  &__wrap {
    max-width: 1440px;
    height: 104px;
    @include media-breakpoint-down(md) {
      max-width: 540px;
      height: 60px;
    }
  }
  &__container {
    @include media-breakpoint-down(sm) {
      height: 60px;
    }
  }

  &__heading {
    font-family: $apercu-regular-pro;
    font-size: 20px;
    background: linear-gradient($dark 0 0) bottom / var(--d, 0) 2px no-repeat;
    transition: 0.5s;
    color: $dark;

    @media only screen and (min-width: 768px) {
      padding-bottom: 5px;
    }

    &:hover {
      --d: 100%;
      text-decoration: transparent;
      background: linear-gradient($orange 0 0) bottom/var(--d, 0) 2px no-repeat;

      .submenu__caret {
        svg {
          path {
            stroke: $orange;
          }
        }
      }
    }

    @include media-breakpoint-down(lg) {
      display: none;
    }

    &.active {
      @media only screen and (min-width: 768px) {
        color: $orange;
      }
    }

    &.show {
      color: $orange;
      .submenu__caret {
        transform: rotate(180deg);
        transition: all 0.5s ease;
        svg {
          path {
            stroke: $orange;
          }
        }

        @media only screen and (min-width: 768px) {
          display: inline-block;
        }
      }
      background: linear-gradient($orange 0 0) bottom/var(--d, 0) 2px no-repeat;
      --d: 100%;
    }
  }

  &__logo img {
    z-index: 15;
    top: 15px;
    left: 60px;
    @include media-breakpoint-down(lg) {
      left: 30px;
    }

    @include media-breakpoint-down(md) {
      top: 5px;
      height: 50px;
      width: 50px;
      left: 30px;
    }

    @media only screen and (min-width: 1920px) {
      left: 90px;
    }

    @media only screen and (min-width: 2560px) {
      left: 120px;
    }
  }

  & .nav__menu {
    right: 60px;

  li {
    margin: 0 30px;
    @include media-breakpoint-up(md) {
      margin: 0 10px;
    }
    @include media-breakpoint-up(lg){
      margin: 0 8px;
    }
    @include media-breakpoint-up(xl){
      margin: 0 26px;
    }

    &:first-child {
      margin-left: 0;
    }
  }

    @include media-breakpoint-down(md) {
      right: 30px;
    }

    @media only screen and (min-width: 1920px) {
      right: 90px;
    }

    @media only screen and (min-width: 2560px) {
      right: 120px;
    }

    .dropdown-menu {
      @media only screen and (min-width: 768px) {
        background-color: $gray;
        padding: 20px;
        border-radius: 0px 0px 20px 20px;
        top: 35px !important;
        border: 0;
        li {
          &:first-child {
            padding-top: 0;
          }
          padding-top: 15px;
          a {
            color: $dark;
            font-size: 1rem;
            font-family: $apercu-regular-pro;
          }
        }
      }
    }
  }

  .tag {
    font-size: 0.5rem;
    line-height: 0.625rem;
    @include media-breakpoint-up(md) {
      font-size: 0.6875rem;
      line-height: 1.5625rem;
    }
  }

  &__contacts-box {
    z-index: 20;
    width: fit-content;

    .tag {
      color: #e5e5e5;
    }
  }

  &__contacts {
    // larger spacing than p & m-60
    margin-bottom: 40px;

    a,
    p {
      font-family: $apercu-regular-pro;
      font-size: 14px;
      line-height: 17px;
      color: #e5e5e5;

      @include media-breakpoint-up(md) {
        font-size: 1.125rem;
        line-height: 1.625rem;
      }
    }
  }

  &__bg-shape {
    position: absolute;
    background-color: #333333;
    overflow: hidden;
    z-index: 15;
    top: 70%;
    margin-left: -60%;
    width: 200%;
    height: 200%;
    transform: rotate(13deg);

    @include media-breakpoint-down(sm) {
      transform: rotate(30deg);
      top: 40%;
      margin-left: -150%;
    }
  }

  li[data-action] {
    &.ignore-pe > * {
      pointer-events: none;
    }
    & > svg {
      transition: opacity 0.5s ease-in-out;
      & + svg {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
      }
    }
    &.open > svg {
      opacity: 0;
      & + svg {
        opacity: 1;
      }
    }
    .nav__heading {
      &::after {
        content: "";
        display: inline-block;
        height: 14px;
        width: 14px;
        background-image: url("/assets/caret.svg");
        background-position: center;
        background-repeat: no-repeat;
        vertical-align: middle;
        margin-left: 10px;
        transition: transform 0.5s ease-in-out;
      }
    }
    &.open .nav__heading {
      --d: 100%;
      text-decoration: transparent;
      background: linear-gradient($orange 0 0) bottom/var(--d, 0) 2px no-repeat;
      color: $sdb-orange-hieroo-dark;
      &::after {
        transform: rotate(180deg);
        filter: $orange-filter
      }
    }
  }

  & > .submenu {
    top: 104px;
    max-height: 0;
    overflow: hidden;
    margin-top: -1px;
    color: $white;
    z-index: 79;
    .sub-overlay {
      display: block;
      content: "";
      background: rgba( 11, 11, 11, 0.8); //needed to be darker to be true to design
      backdrop-filter: blur(10px);
      width: 100%;
      height: 100%;
    }
    &::after {
      display: block;
      content: "";
      background: rgba( 11, 11, 11, 0.8); //needed to be darker to be true to design
      backdrop-filter: blur(10px);
      height: 55px;
      width: 100%;
      clip-path: polygon(0 0,100% 0,100% 100%);
      @include media-breakpoint-down(sm) {
        height: 45px;
      }
    }
    & .submenu-close {
      position: absolute;
      top: 40px;
      right: 40px;
      cursor: pointer;
    }
    @include media-breakpoint-down(md) {
      top: 60px;
    }

    .container {
      padding-top: 120px;
      padding-bottom: 120px;
      @include media-breakpoint-down(sm) {
        padding-top: 60px;
        padding-bottom: 60px;
      }
      .links-col {
        > div {
          margin-bottom: 40px;
          position: relative;
          &:after {
            content: "";
            display: inline-block;
            height: 14px;
            width: 14px;
            background-image: url("/assets/caret.svg");
            background-position: center;
            background-repeat: no-repeat;
            vertical-align: middle;
            position: absolute;
            right: 0;
            top: calc( 50% - 7px );
            filter: invert(91%) sepia(59%) saturate(0%) hue-rotate(10deg) brightness(111%) contrast(101%);
            transform: rotate(270deg);
          }
          &:last-child {
            margin-bottom: 0;
          }
          a {
            color: $white;
            display: inline-block;
            transition: 0.5s background;
            background: linear-gradient(#333 0 0) bottom / var(--d, 0) 2px no-repeat;
            padding-bottom: 5px;
            margin-bottom: 0;
            margin-right: 7%;
            &:hover {
              text-decoration: transparent;
              background: linear-gradient($orange 0 0) bottom / var(--d, 0) 2px no-repeat;
              --d: 100%;
            }
          }
        }
      }
      .cards-grid {
        grid-template-columns: 1fr;
        grid-auto-rows: max-content;
        .highlight_card {
          opacity: 0;
          transition: opacity 0.3s ease-in-out;
          grid-row-start: 1;
          grid-column-start: 1;
          pointer-events: none;
          &.show {
            opacity: 1;
            pointer-events: all;
          }
          .h6 {
            word-break: break-word;
            hyphens: auto;
          }
        }
      }
      .order-md-last .cards-grid .highlight_card {
        transition-delay: 0.02s;
      }
    }
  }
}

main:not(#homepage),
#homepage .hero__content {
  padding-top: 60px;
  @include media-breakpoint-up(sm) {
    padding-top: 160px;
  }
}
// _________________________________________OVERLAY: DROPDOWN_______________________________
.overlay {
  margin-top: -1px;
  height: 1px; // element changes - this can't be 0 or the backdrop-filter won't work on mobile
  z-index: 10;

  .overlay__inner{
    li > a {
      color: white;
    }
  }
  
  &__background {
    background: rgba(
      11,
      11,
      11,
      0.8
    ); //needed to be darker to be true to design
    backdrop-filter: blur(10px);
  }

  &__content {
    z-index: 20;
    top: 120px;
    height: calc(100% - 120px);

    & li :is(a).h1 {
      color: $white;
      margin: 0 !important;
      padding-right: 4rem;
      position: relative;
      line-height: normal;

      &.overlay__dropdown {
        svg {
          content: "";
          display: block;
          height: 16px;
          width: 28px;
          position: absolute;
          right: 0;
          top: calc( 50% - 8px);
          transition: all 0.5s ease-in-out;
        }
        &[aria-expanded="true"] {
          color: $orange;
          svg {
            transform: rotate(180deg);
            path {
              fill: $orange;
            }
          }
        }
      }
    }
  }

  &__inner {
    & li {
      padding-top: 15px;

      a {
        color: $sdb-white-hieroo-dark;
        font-family: $apercu-regular-pro;
        font-size: 1.375rem;
        line-height: 1.375rem;
        &:hover,
        &:focus {
          color: $orange;
          text-decoration: none;
        }
      }

      &:first-child {
        padding-top: 15px;
      }
    }
  }

  .container {
    svg {
      position: relative;
      float: right;
      top: 20px;
    }
  }
}

.collapse {
  &:not(.show) {
    transition: ease;
    visibility: hidden;
  }
}

.collapsing {
  overflow: hidden;
  @include transition($transition-collapse);
}

// __________________________________MENU OPEN SLIDE DOWN_____________________________

.heightChange {
  height: 100%;
  animation-name: reveal;
  animation-duration: 0.4s;
  animation-timing-function: linear;
}

@keyframes reveal {
  0% {
    clip-path: polygon(0 0, 100% 0%, 100% 0%, 0% 25%);
  }
  25% {
    clip-path: polygon(0 0, 100% 0%, 100% 30%, 0% 75%);
  }
  60% {
    clip-path: polygon(0 0, 100% 0%, 100% 60%, 0% 95%);
  }
  100% {
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
  }
}
.overlayCollapse {
  animation-name: collapse;
  animation-duration: 0.3s;
  animation-timing-function: linear;
}

@keyframes collapse {
  0% {
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
    height: 100vh;
  }
  25% {
    clip-path: polygon(0 0, 100% 0%, 100% 95%, 0% 45%);
  }
  60% {
    clip-path: polygon(0 0, 100% 0%, 100% 75%, 0% 15%);
  }
  100% {
    clip-path: polygon(0 0, 100% 0%, 100% 25%, 0% 0%);
    height: 0;
  }
}

.submenu-height-change {
  max-height: 1000px !important;
  animation-name: reveal;
  animation-duration: 0.4s;
  animation-timing-function: linear;
}
.submenu-collapse {
  animation-name: submenu-collapse;
  animation-duration: 0.3s;
  animation-timing-function: linear;
}
@keyframes submenu-collapse {
  0% {
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
    max-height: 1000px;
  }
  25% {
    clip-path: polygon(0 0, 100% 0%, 100% 95%, 0% 45%);
  }
  60% {
    clip-path: polygon(0 0, 100% 0%, 100% 75%, 0% 15%);
  }
  100% {
    clip-path: polygon(0 0, 100% 0%, 100% 25%, 0% 0%);
    max-height: 0;
  }
}

// __________________________________BURGER MENU_____________________________

#openNav {
  display: block;
  position: relative;
  z-index: 20;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
}

#openNav input {
  display: block;
  width: 40px;
  height: 32px;
  position: absolute;
  top: -7px;
  left: -5px;
  cursor: pointer;
  opacity: 0; /* hide this */
  z-index: 30; /* and place it over the hamburger */
  -webkit-touch-callout: none;
}

#openNav span {
  display: block;
  width: 33px;
  height: 2px;
  margin-bottom: 5px;
  position: relative;
  background: black;
  z-index: 20;
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
    background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
}

#openNav svg {
  display: none;
}

#openNav.open svg {
  display: block;
}

#openNav.open span {
  display: none;
}

#openNav input:checked ~ span {
  opacity: 1;
  background: white;
}

#openNav input:checked ~ span:nth-last-child(1) {
  transform: translateY(-4px) rotate(45deg);
}

#openNav input:checked ~ span:nth-last-child(3) {
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}

#openNav input:checked ~ span:nth-last-child(2) {
  transform: translateY(3px) rotate(-45deg);
}
