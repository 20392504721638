.btn__sec,
.btn__sml,
.btn__lrg,
.btn__xs {
    position: relative;
    border: 0;
    border-radius: $border-radius-cta;
    color: $sdb-white-hieroo-dark;
    align-items: center;
    justify-content: center;
    padding: 0.1rem 1.875rem 0;
    white-space: nowrap;
    display: inline-flex;
    transition: all .4s;
    overflow: hidden;
    z-index: 1;
    text-align: center;
    height: 40px;

    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $orange;
        border-radius: 10rem;
        z-index: -2;
    }
    &:before {
        @include media-breakpoint-up(xl){
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 0%;
            height: 100%;
            background-color: $dark;
            transition: all .4s ease-in-out;
            border-radius: 10rem;
            z-index: -1;
        }
    }
    &:hover {
        color: $white;
		text-decoration: none;
        &:before {
            width: 100%;
        }
    }

    &._black {
        &:after {
            background-color: $dark;
        }
    }

    &._white {
        color: $dark;
        &:after {
            background-color: $white;
        }
        &:hover {
            color: $white;
            &:after {
                background-color: $dark;
            }
        }
    }
}


/* Large Button */
.btn__lrg {
	@include media-breakpoint-up(md){
		min-width: 182px;
	}
}

/* 	Small Button 
	Only use to anchor component on mobile
*/
.btn__sml {
	min-width: 148px;
	height: 32px;
	font-size: 0.875rem;
    padding: 0.1rem 1.875rem 0;
    align-items: center;
}

/* 	XS Button 
	Used in cookie bar
*/
.btn__xs {
	min-width: 98px;
	height: 30px;
	font-size: 0.875rem;
    line-height: 0.875rem;
    padding: 0.5rem 1.25rem;
    align-items: center;
}
/* 	Inversed Button 
	Used in cookie bar
*/
.btn__sec,
.btn__inv {
	color: $dark;
    border: 1px solid $dark;
    &:after {
        background-color: transparent;
    }
    &:before {
        @include media-breakpoint-up(xl){
            background-color: transparent;
        }
    }
    &:hover {
        color: $dark;
    }
}
.btn__sec {
    padding: 0.625rem 1.25rem;
    display: flex; /* Currently not used inline in any designs */
    background-color: transparent;
    &:hover {
        background-color: $dark;
        color: $white;
    }
}

/* Hero Scroll Button */
.scroll {
	border-radius: 30px;
	border: 1px solid $white;
	width: 30px;
	height: 30px;

	&:hover {
		background-color: $dark;
		color: $white; 
	}
}

/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;

    /* Hide default HTML checkbox */
    input {
        opacity: 0;
        width: 0;
        height: 0;
    }
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $placeholderGray;
    -webkit-transition: .4s;
    transition: .4s;

    &:before {
        position: absolute;
        content: "";
        height: 14px;
        width: 14px;
        left: 3px;
        bottom: 3px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
    }
    /* Rounded sliders */
    &.round {
        border-radius: 20px;
        &:before {
            border-radius: 50%;
        }
    }
}

input:checked {
    &+.slider {
        background-color: $orange;
        &:before {
            -webkit-transform: translateX(20px);
            -ms-transform: translateX(20px);
            transform: translateX(20px);
        }
    }
    &:disabled {
        &+.slider {
            background-color: transparent;
            border: 1px solid $placeholderGray;
            cursor: default;
            &:before {
                background-color: $placeholderGray;
                bottom: 2px;
            }
        }
    }
}

input:focus+.slider {
    box-shadow: 0 0 1px $orange;
}