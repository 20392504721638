.block-afbeelding {
    &__quote {
        left: 12px;
        right: 12px;
    }
    &.start-img {
        @include media-breakpoint-down(md) {
            .col.position-relative {
                min-height: 520px;
            }
            img,
            .img-overlay-orange {
                height: 520px;
            }
            img {
                position: absolute;
                width: auto !important;
                max-width: unset;
            }
            .img-overlay-orange {
                position: relative;
            }
        }
    }
}
